import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from "@angular/core";
import { GridColumn } from 'src/app/shared/grid/DTO/gridColumn.model';
import { GridComponent } from 'src/app/shared/grid/grid.component';
import { SalesService } from '../sales.service';
import { ValueOrEmpty, CreateNewGridColumn } from 'src/app/shared/grid/grid.helper';
import { formatCurrency } from "@angular/common";
import { AnalyticsService, EventNames } from "src/app/shared/analytics.service";

@Component({
  selector: 'app-saleitens',
  templateUrl: './saleitens.component.html',
  styleUrls: ['./saleitens.component.scss']
})
export class SaleItensComponent implements OnInit {

  @Input() dataSource: any;
  @Output() eventRefreshFinish = new EventEmitter();
  @ViewChild('gridsalesdocumentitems') gridsaledocumentItem: GridComponent;

  itenmDocumentsGridColumns: GridColumn[] = [];

  constructor(
    private salesService: SalesService,
    private analyticsService: AnalyticsService) { }

  ngOnInit() {
    this.itenmDocumentsGridColumns.push(CreateNewGridColumn(50, 'numero', 'No.', (element: any) => `${element.numero}`));
    this.itenmDocumentsGridColumns.push(CreateNewGridColumn(120, 'codigo', 'Código', (element: any) => `${element.codigo}`));
    this.itenmDocumentsGridColumns.push(CreateNewGridColumn(200, 'descricao', 'Descrição', (element: any) => `${element.descricao}`));
    this.itenmDocumentsGridColumns.push(CreateNewGridColumn(80, 'ncm', 'NCM', (element: any) => `${element.ncm}`));
    this.itenmDocumentsGridColumns.push(CreateNewGridColumn(70, 'quantidade', 'Qtd.', (element: any) => `${element.quantidade}`));
    this.itenmDocumentsGridColumns.push(CreateNewGridColumn(70, 'cst', 'CST', (element: any) => `${ValueOrEmpty(element.cst)}`));
    this.itenmDocumentsGridColumns.push(CreateNewGridColumn(70, 'aliquota', 'Alíquota', (element: any) => `${element.aliquota}`));
    this.itenmDocumentsGridColumns.push(CreateNewGridColumn(70, 'base_icms', 'Base ICMS', (element: any) => `${element.base_icms}`));
    this.itenmDocumentsGridColumns.push(CreateNewGridColumn(70, 'valor_icms', 'Valor ICMS', (element: any) => `${element.valor_icms}`));
    this.itenmDocumentsGridColumns.push(CreateNewGridColumn(70, 'valor_unitario', 'Valor unit.', (element: any) => `${element.valor_unitario}`));
    this.itenmDocumentsGridColumns.push(CreateNewGridColumn(90, 'valor_total', 'Total', (element: any) => `${element.valor_total}`));
  }

  GetInvoiceItemDetail(chave: any): void {
    this.analyticsService.logEvent(EventNames.SELECT_CONTENT, {
      content_type: 'Visualizacao',
      item_id: 'DetalheItemsVendas',
    });
    this.salesService.GetInvoiceItemDetail(chave).subscribe(data => {
      data.itens.forEach(element => {
        element.valor_total = formatCurrency(Number(element.valor_total), 'pt-BR','','BRL').trim();
        element.valor_unitario = formatCurrency(Number(element.valor_unitario), 'pt-BR','','BRL').trim();
      });
      
      this.dataSource = data;
      
      if (this.gridsaledocumentItem)
        this.gridsaledocumentItem.refreshDateSource(this.dataSource.itens);
    });
  }

  maskDate(d: Date): string {
    return new Date(d).toLocaleString('pt-BR');
  }

  FinishDataClicked(): void {
    this.eventRefreshFinish.emit();
  }
}