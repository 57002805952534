import { Component, OnInit, Input } from '@angular/core';
import { AnalyticsService, EventNames } from 'src/app/shared/analytics.service';

@Component({
  selector: 'app-faq-box',
  templateUrl: './faq-box.component.html',
  styleUrls: ['./faq-box.component.scss']
})
export class FaqBoxComponent implements OnInit {

  constructor(private analyticsService: AnalyticsService) { }

  @Input() iconPath: string;
  @Input() title: string;
  @Input() text: string;
  @Input() url: string;

  ngOnInit() {
  }

  goTo(url: string) {
    this.analyticsService.logEvent(EventNames.SELECT_CONTENT, {
      content_type: 'Funcionalidade',
      item_id: 'Faq'
    })
    window.open(url, '_blank');
  }

}
