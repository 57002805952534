import { Component, OnInit, ViewChild, ElementRef, NgModule } from "@angular/core";
import { Router } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { first } from "rxjs/operators";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { LoginService } from "./login.service";
import { LoginRequest } from "./DTO/loginRQ";
import { ToastrService } from 'ngx-toastr';
import { DomeDictionary } from '../dictionary';
import { UserIdleService } from 'angular-user-idle';
import { ModalService } from "../shared/modal/modal.service";
import { Config } from "../config";
import { AnalyticsService, EventNames } from "../shared/analytics.service";


@Component({ templateUrl: "login.component.html", selector: "app-login", styleUrls: ['./login.component.scss'] })
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  submitted = false;
  error: string;
  captchaResolved = false;
  projectConfig = Config;
  blockLogin = window.location.href.includes('linxdome');
  captchaKey = '6LdwT7IqAAAAAORC0xARrL1hjLXsstGT9NA3LFNz'

  @ViewChild("myPassword") myPassword: ElementRef;
  @ViewChild("myUserName") myUserName: ElementRef;

  navidateTo: string;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private loginService: LoginService,
    private ngxLoader: NgxUiLoaderService,
    private toastr: ToastrService,
    private userIdle: UserIdleService,
    private modalService: ModalService,
    private analyticsService: AnalyticsService
  ) { }

  ngOnInit() {

    this.loginForm = this.formBuilder.group({
      UserName: ["", Validators.compose([Validators.required])],
      Password: ["", Validators.compose([Validators.required])]
    });

    this.navidateTo = btoa("/dashboard");
  }

  setFocusPassword() {
    this.myPassword.nativeElement.focus();
  }
  setFocusUserName() {
    this.myUserName.nativeElement.focus();
  }

  onSubmit() {
    sessionStorage.clear();
    localStorage.clear();
    this.submitted = true;

    if (!this.loginForm.valid) {
      this.toastr.info(DomeDictionary.DadosInvalidos, "");
      return;
    }

    if(!this.captchaResolved) {
      this.toastr.info("Resolva o captcha para continuar.");
      return;
    }

    this.ngxLoader.start();
    var request: LoginRequest = {
      UserName: this.loginForm.controls.UserName.value,
      Password: this.loginForm.controls.Password.value,
      RefreshToken: "",
      GrantType: "password"
    };
    this.analyticsService.logEvent(EventNames.LOGIN, { method: "email" });
    this.loginService
      .login(request)
      .pipe(first())
      .subscribe(
        async data => {
          if (!data.authenticated) {
            this.handleLoginError(data);
          }

          if (!await this.loginService.checkLicense()) {
            return;
          }
          if (data.necessitaAtualizarInformacoes) {
            this.loginService.getEmitterData(data.economicGroup.emitters[0].cnpjCpf).subscribe((data) => {
              this.modalService.open('open-complete-registration');
            })
          }
          this.ngxLoader.stop();
          if (!data.authenticated) {
            this.toastr.error(data.message, "")
          } else if (data.forceChangePassword) {
            this.router.navigate(["/alterar-senha"]);
          } else if (data.forceCompleteRegistration) {
            this.router.navigate(["/completar-cadastro"]);
          } else {
            this.configureUserIdle();
            this.router.navigate([atob(this.navidateTo)]);
          }
        },
        error => this.handleLoginError(error),
        () => this.ngxLoader.stop()
      );
  }

  handleLoginError(error) {
    this.ngxLoader.stop();
    if(!error || !error.erros) {
      this.toastr.info("Ocorreu um erro ao realizar login, por favor verifique os dados e tente novamente.", "");
      return;
    }
    if (error.erros) {
      this.toastr.info(error.erros[0].descricao, "");
    } else if (error.message) {
      if (error.name === "HttpErrorResponse") {
        this.toastr.info(DomeDictionary.ErroConexaoServidor, "");
      } else {
        this.toastr.info(error.message, "");
      }
    }
  }

  openNewUrl() {
    window.open(this.projectConfig.NewUrl());
  }

  copyToClipboard() {
    navigator['clipboard'].writeText(this.projectConfig.NewUrl());
    this.toastr.info('Url copiada com sucesso!')
  }

  private configureUserIdle() {
    this.userIdle.startWatching();
    this.userIdle.onTimerStart().subscribe();

    this.userIdle.onTimeout().subscribe(() => {
      let message = 'Sua sessão expirou! Faça login novamente.';
      this.toastr.info(message);
      this.loginService.logout();
      this.userIdle.resetTimer();
      this.userIdle.stopTimer();
      this.userIdle.stopWatching();
    });
  }

  openForgotMyPassword() {
    this.router.navigate(["/esqueci-minha-senha"]);
  }
}
