import { CompleteRegistrationModalComponent } from './complete-registration-modal/complete-registration-modal.component';
import { GerenciarPerfisComponent } from './gerenciar-perfis/gerenciar-perfis.component';
import { TotalsComponent } from './purchase/documents/totals/totals.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxCoolDialogsModule } from 'ngx-cool-dialogs';
import { NgxMaskModule } from 'ngx-mask';
import { JwtInterceptor } from './_helpers/jwt.interceptor';
import { routing } from './app.routing';
import { HomeComponent } from './home/home.component';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { PrimeiroAcessoComponent } from './login/primeiro-acesso/primeiro-acesso.component';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { GroupOrEmitterComponent } from './nav-menu/GroupOrEmitter/grouporemitter.component';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { SalesComponent } from './sales/sales.component';
import { PurchaseComponent } from './purchase/purchase.component'
import { ExportMateralModule } from './exportmateralmodule';
import { DatePipe } from '@angular/common';
import { CNPJPipe } from './shared/Util/Pipes/CNPJPipe';
import { PurchaseNteDetailComponent } from './purchase/purchasentedetail/purchasentedetail.component';
import { PurchaseItensDetailComponent } from './purchase/purchaseitensdetail/purchaseitensdetail.component';
import { PurchaseEventsDetailComponent } from './purchase/purchaseeventsdetail/purchaseeventsdetail.component';
import { PurchaseitemdetailmodalComponent } from './purchase/purchaseitemdetailmodal/purchaseitemdetailmodal.component';
import { PurchaseManifestsDetailComponent } from './purchase/purchasemanifestsdetail/purchasemanifestsdetail.component';
import { PurchaseAuditsDetailComponent } from './purchase/purchaseauditsdetail/purchaseauditsdetail.component';
import { PurchaseCteDetailComponent } from './purchase/purchasectedetail/purchasectedetail.component';
import { PurchaseemitterdetailComponent } from './purchase/purchaseemitterdetail/purchaseemitterdetail.component';
import { TabsComponent } from './shared/tabs/tabs.componet';
import { SaleGradesComponent } from './sales/salegrades/salegrades.component';
import { SaleItensComponent } from './sales/saleitens/saleitens.component';
import { SaleEmitterComponent } from './sales/saleemitter/saleemitter.component';
import { SaleSubjectComponent } from './sales/salesubject/salesubject.component';
import { SaleDocumentsComponent } from './sales/saledocuments/saledocuments.component';
import { SaleInutdetailComponent } from './sales/saleinutdetail/saleinutdetail.component';
import { ConformityComponent } from './conformity/conformity.component';
import { SharedModule } from './shared/shared.module';
import { InsightsComponent } from './insights/insights.component';
import { MenuComponent } from './home/menu/menu.component';
import { FooterComponent } from './home/footer/footer.component';
import { UserComponent } from './user/user.component';
import { UserDataDetailComponent } from './user/userdatadetails/userdatadetail.component';
import { UserSignatureDetailComponent } from './user/usersignaturedetail/usersignaturedetail.component';
import { FaqComponent } from './faq/faq.component';
import { FaqBoxComponent } from './faq/faq-box/faq-box.component';
import { FaqDuvidasComponent } from './faq/faq-duvidas/faq-duvidas.component';
import { ConformityTaxGuideComponent } from './conformity/conformitytaxguide/conformitytaxguide.component';
import { ConformityDeclarationTaxComponent } from './conformity/conformitydeclarationtax/conformitydeclarationtax.component';
import { ChangePasswordComponent } from './user/change-password/change-password.component';
import { ErrorInterceptor } from './_helpers/error.interceptor';
import { ChangePasswordFormComponent } from './user/change-password/change-password-form.component';
import { ForgotMyPasswordFormComponent } from './user/forgot-my-password/forgot-my-password-form.component';
import { ForgotMyPasswordComponent } from './login/forgot-my-password/forgot-my-password.component';
import { AngularValidateBrLibModule } from 'angular-validate-br';
import { ConformityFileExtractionComponent } from './conformity/conformityfileextraction/conformityfileextraction.component';
import { CompleteRegistrationComponent } from './user/complete-registration/complete-registration.component';
import { UserEmailConfigComponent } from './user/useremailconfig/useremailconfig.component';
import { AccountantIntegrationComponent } from './accountantintegration/accountantintegration.component';
import { AccountIntegrationInconsistencyComponent } from './accountantintegration/accountintegrationinconsistency/accountintegrationinconsistency.component';
import { NgxEchartsModule } from 'ngx-echarts';
import { ChartPurchaseComponent } from './dashboard/chart-purchase/chart-purchase.component';
import { ChartSaleComponent } from './dashboard/chart-sale/chart-sale.component';
import { ChartTaxAuditComponent } from './dashboard/chart-tax-audit/chart-tax-audit.component';
import { ChartManifestationComponent } from './dashboard/chart-manifestation/chart-manifestation.component';
import { ChartInsightsComponent } from './dashboard/chart-insights/chart-insights.component';
import { ToastrModule } from 'ngx-toastr';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { MatPaginatorIntl } from '@angular/material';
import { PtMatPaginatorIntl } from './shared/grid/pt.mat.paginator';
import { AlterarFilialComponent } from './home/alterar-filial/alterar-filial.component';
import { InconsistencyManifestationDetailsComponent } from './accountantintegration/accountintegrationinconsistency/inconsistency-manifestation-details/inconsistency-manifestation-details.component';
import { InsightsPendentesComponent } from './insights/insights-pendentes/insights-pendentes.component';
import { InsightsAprovadosComponent } from './insights/insights-aprovados/insights-aprovados.component';
import { InsightsRecusadosComponent } from './insights/insights-recusados/insights-recusados.component';
import { CalculoDASComponent } from './conformity/calculo-das/calculo-das.component';
import { UserIdleModule } from 'angular-user-idle';
import { Config } from './config';
import { ChartRejectionsComponent } from './sales/chart-rejections/chart-rejections.component';
import { InsightsConsultasComponent } from './insights/insights-consultas/insights-consultas.component';
import { InsightsConsultasCalculoComponent } from './insights/insights-consultas-calculo/insights-consultas-calculo.component';
import { ManageUsersComponent } from './manage-users/manage-users.component';
import { ManageUsersDetailsComponent } from './manage-users/manageUsersDetails/manageUsersDetails.component';
import { ManageUsersAccessComponent } from './manage-users/manageUsersAccess/manageUsersAccess.component';
import { ManageUsersChangeHistoryComponent } from './manage-users/manageUsersChangeHistory/manageUsersChangeHistory.component';
import { ChargeComponent } from './purchase/documents/charge/charge.component';
import { EmissionDataComponent } from './purchase/documents/emission-data/emission-data.component';
import { EventsComponent } from './purchase/documents/events/events.component';
import { ProductsAndServicesComponent } from './purchase/documents/products-and-services/products-and-services.component';
import { TaxAuditorComponent } from './purchase/documents/tax-auditor/tax-auditor.component';
import { TransportComponent } from './purchase/documents/transport/transport.component';
import { ConformityExtractReportsComponent } from './conformity/conformityextractreports/conformityextractreports.component';
import { AvTaxComplianceComponent } from './av-tax-compliance/av-tax-compliance.component';
import { MenuMultiLevelModule } from './shared/menu-multi-level/menu-multi-level.module';
import { HistoricoDfeComponent } from './purchase/historico-dfe/historico-dfe.component';
import { LicencaComponent } from './licenca/licenca.component';
import { RecaptchaModule } from 'ng-recaptcha/';

@NgModule({
  declarations: [
    AppComponent,
    NavMenuComponent,
    LoginComponent,
    HomeComponent,
    PrimeiroAcessoComponent,
    DashboardComponent,
    GroupOrEmitterComponent,
    SalesComponent,
    PurchaseComponent,
    CNPJPipe,
    PurchaseNteDetailComponent,
    PurchaseItensDetailComponent,
    PurchaseEventsDetailComponent,
    PurchaseManifestsDetailComponent,
    PurchaseAuditsDetailComponent,
    PurchaseCteDetailComponent,
    PurchaseemitterdetailComponent,
    PurchaseCteDetailComponent,
    PurchaseitemdetailmodalComponent,
    TabsComponent,
    SaleGradesComponent,
    SaleItensComponent,
    SaleSubjectComponent,
    SaleEmitterComponent,
    SaleDocumentsComponent,
    SaleInutdetailComponent,
    ConformityComponent,
    InsightsComponent,
    MenuComponent,
    FooterComponent,
    FaqComponent,
    FaqBoxComponent,
    FaqDuvidasComponent,
    UserComponent,
    UserDataDetailComponent,
    UserSignatureDetailComponent,
    ConformityTaxGuideComponent,
    ConformityDeclarationTaxComponent,
    ChangePasswordComponent,
    ChangePasswordFormComponent,
    ForgotMyPasswordFormComponent,
    ForgotMyPasswordComponent,
    ConformityFileExtractionComponent,
    CompleteRegistrationComponent,
    UserEmailConfigComponent,
    AccountantIntegrationComponent,
    AccountIntegrationInconsistencyComponent,
    ChartPurchaseComponent,
    ChartSaleComponent,
    ChartTaxAuditComponent,
    ChartManifestationComponent,
    ChartInsightsComponent,
    AlterarFilialComponent,
    InconsistencyManifestationDetailsComponent,
    InsightsPendentesComponent,
    InsightsAprovadosComponent,
    InsightsRecusadosComponent,
    CalculoDASComponent,
    ChartRejectionsComponent,
    InsightsConsultasComponent,
    InsightsConsultasCalculoComponent,
    ManageUsersComponent,
    ManageUsersDetailsComponent,
    ManageUsersAccessComponent,
    ManageUsersChangeHistoryComponent,
    ChargeComponent,
    EmissionDataComponent,
    EventsComponent,
    ProductsAndServicesComponent,
    TaxAuditorComponent,
    TotalsComponent,
    TransportComponent,
    ConformityExtractReportsComponent,
    AvTaxComplianceComponent,
    GerenciarPerfisComponent,
    CompleteRegistrationModalComponent,
    HistoricoDfeComponent,
    LicencaComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: "ng-cli-universal" }),
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    routing,
    MenuMultiLevelModule,
    BrowserAnimationsModule,
    NgxMaskModule.forRoot(),
    NgxCoolDialogsModule.forRoot({
      theme: "default",
      okButtonText: "Sim",
      cancelButtonText: "Não",
      color: "#411E5A",
      titles: {
        alert: "Atenção!",
        confirm: "Confirmar",
        prompt: "Informe"
      }
    }),
    NgxUiLoaderModule,
    ExportMateralModule,
    SharedModule.forRoot(),
    AngularValidateBrLibModule,
    NgxEchartsModule,
    ToastrModule.forRoot({
      timeOut: 5000,
      extendedTimeOut: 1000,
      positionClass: 'toast-top-right',
      preventDuplicates: true,
      closeButton: true
    }),
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    UserIdleModule.forRoot(Config.UserIdleConfiguration),
    RecaptchaModule.forRoot()
  ],
  entryComponents: [],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true
    },
    {
      provide: MatPaginatorIntl,
      useClass: PtMatPaginatorIntl
    },
    DatePipe
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent],
  exports: [CalculoDASComponent, InsightsConsultasCalculoComponent]
})
export class AppModule { }
