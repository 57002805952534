import { Injectable } from '@angular/core';
import { LoginService } from '../login/login.service';
declare let gtag: Function;

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {

  constructor(private loginService: LoginService) { }

  public logEvent(eventName: string, params: any) {
    let cnpj = '';
    if (this.loginService.user && this.loginService.user.economicGroup) {
      cnpj = this.loginService.user.economicGroup.emitters[0].cnpjCpf;
    }
    gtag('event', eventName, {
      user_id: cnpj ? cnpj : 'anonymous',
      ...params
    });
  }
}

export enum EventNames {
  LOGIN = 'login',
  SEARCH = 'search',
  SELECT_CONTENT = 'select_content',
  SIGN_UP = 'sign_up'
}